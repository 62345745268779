import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import './jsonEditor.css';
import { useWindowSize } from '../../hooks/useWindowSize';
import { setSyncTimeout } from '../../helpers/setSyncTimeout';

import JsonEditorDisabledState from './JsonEditorDisabledState/JsonEditorDisabledState';

JsonEditor.propTypes = {
    mode: PropTypes.oneOf(['view', 'code']).isRequired, // if user should be able to edit or create json use "code", otherwise use "view".
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    setValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    shouldRefreshValue: PropTypes.bool, // When the JsonEditor component mounts, the initial value is set, which can only be changed by the user through keyboard input (uncontrolled typing). However, if it's necessary to programmatically change the value in the JSON Editor, then set shouldRefreshValue = True, and the new value will be set.
    setShouldRefreshValue: PropTypes.func,
    autoExpand: PropTypes.bool,
};

function JsonEditor({
    value,
    setValue,
    mode,
    disabled = false,
    shouldRefreshValue = false,
    setShouldRefreshValue,
    autoExpand,
}) {
    const containerRef = useRef(null);
    const editor = useRef(null);

    const { width: screenWidth } = useWindowSize();

    const [jsonEditorHeight, setJsonEditorHeight] = useState(0);

    useEffect(() => {
        const container = containerRef.current;

        if (container) {
            const options = {
                mode,
                onChangeText: (value) => {
                    setValue(value);

                    if (autoExpand && mode === 'code') {
                        setSyncTimeout(updateEditorHeight, 20);
                    }
                },
                search: false,
            };
            editor.current = new JSONEditor(container, options);
            editor.current.set(value || {});
        }

        return () => {
            if (editor.current) {
                editor.current.destroy();
            }
        };
    }, [containerRef]);

    useEffect(() => {
        if (shouldRefreshValue && editor.current) {
            // set new value
            editor.current.set(value);
            setShouldRefreshValue(false);
        }
    }, [shouldRefreshValue]);

    function updateEditorHeight() {
        if (editor.current && mode === 'code') {
            const height = editor.current.aceEditor?.renderer?.layerConfig?.maxHeight;

            if (height) {
                setJsonEditorHeight(height);
            }
        }
    }

    useEffect(() => {
        if (autoExpand) {
            updateEditorHeight();
        }
    }, [editor.current, screenWidth, autoExpand, disabled]);

    const containerClassName = classNames('min-h-full', {
        'editor-view-mode': mode === 'view',
        'editor-code-mode': mode === 'code',
        'flex-grow': !autoExpand,
        hidden: disabled,
    });
    const style = {
        height:
            autoExpand && !disabled
                ? `${jsonEditorHeight + 100}px`
                : !autoExpand && disabled
                ? `100px` // the editor will have a height of at least 100 pixels, but it will expand to fill the entire height of its parent component due to the minHeight: '100%' property.
                : '100%',
    };

    return (
        <>
            <div className={containerClassName} ref={containerRef} style={style}></div>

            {disabled && <JsonEditorDisabledState value={value} autoExpand={autoExpand} />}
        </>
    );
}

export default JsonEditor;
