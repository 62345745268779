import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import client from '../../services/general-api';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import FormFieldWrapper from '../FormFieldWrapper/FormFieldWrapper';
import PaginatedSelect from '../PaginatedSelect/PaginatedSelect';

const SingleDatabaseInput = ({
    name,
    valueObj,
    details,
    state,
    handleChange,
    label,
    isRequired = false,
    errorMessage = '',
    tipText = '',
    placeholder = 'Select an option',
}) => {
    const { endpoint, filters, fields } = details;
    const { label: labelFieldName, value: valueFieldName } = fields || {};

    const formattedSearchParams = useRef(() =>
        Object.entries(filters).reduce((acc, [key, value]) => {
            const formattedValue = Array.isArray(value) ? value.join(',') : value;
            return { ...acc, [key]: formattedValue };
        }, {})
    );

    const { options, optionsLoading, totalOptions, setPage, canLoadMoreOptions } =
        useFetchOptionsForPaginatedSelectWithSWR({
            client,
            route: endpoint,
            searchParams: formattedSearchParams.current || {},
            formatResponseToOptions,
        });

    function formatResponseToOptions(results) {
        return results?.map((item) => ({
            id: item.id,
            name: item[labelFieldName],
            value: item[valueFieldName],
        }));
    }

    const handleSelectChange = (_, { id, name, value }) => {
        handleChange((item) => ({ ...item, value: { id, value, label: name } }));
    };

    const formattedValue = valueObj
        ? { id: valueObj.id, name: valueObj.label, value: valueObj.value }
        : null;

    return (
        <FormFieldWrapper
            label={label}
            isRequired={isRequired}
            state={state}
            errorMessage={errorMessage}
            tipText={tipText}
        >
            <PaginatedSelect
                name={name}
                size="sm"
                value={valueObj?.id}
                options={options}
                selectedOptionData={formattedValue}
                optionsLoading={optionsLoading}
                state={state}
                fetchOptions={() => setPage((page) => page + 1)}
                canLoadMore={canLoadMoreOptions}
                includeClientSideFiltering
                totalOptionsCount={totalOptions}
                onChange={handleSelectChange}
                placeholder={placeholder}
            />
        </FormFieldWrapper>
    );
};

SingleDatabaseInput.propTypes = {
    name: PropTypes.string,
    valueObj: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.any,
        label: PropTypes.string.isRequired,
    }).isRequired,
    details: PropTypes.shape({
        endpoint: PropTypes.string.isRequired,
        filters: PropTypes.object,
        fields: PropTypes.shape({
            key: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    state: PropTypes.oneOf(['default', 'error', 'disabled']),
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    errorMessage: PropTypes.string,
    tipText: PropTypes.string,
    placeholder: PropTypes.string,
};

export default SingleDatabaseInput;
