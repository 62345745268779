import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useClickOutside } from 'hooks';
import { ArrowDownSLineIcon } from 'design-system/Icons';
import SvgIcon from 'design-system/SvgIcon/SvgIcon';

import classNames from 'classnames';
import ErrorWarningLineIcon from 'design-system/Icons/ErrorWarningLineIcon';
import { Badge } from '../index';
import Loading from '../../components/Loading';
import { CloseCircleLineIcon } from '../Icons';
import ClientSideFilteredSearchBar from '../../components/ClientSideFilteredSearchBar/ClientSideFilteredSearchBar';
import SelectOption from '../SelectOption/SelectOption';
import Tag from '../Tag/Tag';

Select.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            logAs: PropTypes.string,
            badgeColor: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime']),
            leadingIcon: PropTypes.func,
            leadingIconColor: PropTypes.string,
            trailingBadge: PropTypes.shape({
                text: PropTypes.string.isRequired,
                color: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime']),
            }),
            trailingTagText: PropTypes.string,
        })
    ).isRequired,
    optionsLoading: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
    value: PropTypes.string,
    errorMessage: PropTypes.string,
    size: PropTypes.oneOf(['md', 'sm', 'xs']).isRequired,
    state: PropTypes.oneOf(['default', 'error', 'disabled']),
    tipText: PropTypes.string,
    showAsBadge: PropTypes.bool,
    showOptionsAsBadge: PropTypes.bool,
    badgeColor: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime']),
    placeholder: PropTypes.string,
    includeRemoveAction: PropTypes.bool,
    handleRemoveSelectedValue: PropTypes.func,
    dropdownHeight: PropTypes.number,
    includeClientSideFiltering: PropTypes.bool,
    startingOptionCountForFilterBar: PropTypes.number,
    useExternalDropdownState: PropTypes.bool,
    dropdownOpen: PropTypes.bool,
    setDropdownOpen: PropTypes.func,
    disableSorting: PropTypes.bool,
    areOptionsDisabled: PropTypes.bool,
    increaseComponentHeightBy: PropTypes.number,
};

export default function Select({
    label,
    name,
    size,
    isRequired = false,
    state,
    options,
    optionsLoading = false,
    onChange,
    value,
    logAs,
    errorMessage,
    tipText,
    showAsBadge = false,
    badgeColor = 'blue',
    showOptionsAsBadge = false,
    placeholder = 'Select an option',
    includeRemoveAction = false,
    handleRemoveSelectedValue = () => {},
    dropdownHeight = 240,
    includeClientSideFiltering = false,
    startingOptionCountForFilterBar = 6, // if includeClientSideFiltering = true, then the Filtering SearchBar will be displayed if options count >= startingOptionCountForFilterBar
    filterSearchBarPlaceholder,
    useExternalDropdownState = false, // dropdown open / close state is in parent Component
    dropdownOpen = false, // this value is used instead of open state if useExternalDropdownState = true
    setDropdownOpen = () => {}, // update external open state if useExternalDropdownState = true
    disableSorting = false, // by default in select the sorted options are displayed, use this prop to display unsorted options
    areOptionsDisabled = false,
    increaseComponentHeightBy = 0, // if you want to increase the height of the component when the dropdown is open (in px)
}) {
    const [open, setOpen] = useState(false);
    const selectedOption = options.find((option) => option.id === value);
    const sortedOptions = disableSorting
        ? options
        : options.sort((a, b) => a.name.localeCompare(b.name)) || [];

    const [filteredOptions, setFilteredOptions] = useState(sortedOptions);

    const showClientSideFilterBar =
        includeClientSideFiltering && options?.length >= startingOptionCountForFilterBar;

    useEffect(() => {
        setFilteredOptions(sortedOptions);
    }, [options]);

    const sizes = {
        md: 'h-13 py-17 px-11',
        sm: 'h-12 py-15 px-11',
        xs: 'h-10 py-11 px-11',
    };

    const selectSize = sizes[size];

    let selectClassName = classNames(
        'w-full rounded-2 text-base text-neutral-500 font-normal disabled:text-neutral-300 leading-1.25 placeholder-neutral-300 focus:placeholder-transparent caret-neutral-500 border-neutral-300 border-1 focus:border-1 focus:outline-0 focus:shadow-l3 transition-all ease-in',
        selectSize,
        {
            'bg-white': state !== 'disabled',
            'bg-neutral-50': state === 'disabled',
            'border-neutral-300 focus:shadow-neutral-300': state !== 'error',
            'border-red-500 focus:shadow-red-500': state === 'error',
        }
    );

    const toggleOpen = () => {
        if (useExternalDropdownState) {
            setDropdownOpen((prevState) => !prevState);
        } else {
            setOpen((prevState) => !prevState);
        }
    };

    const dropdownRef = useRef('inputMenu');
    useClickOutside(dropdownRef, () => {
        if (useExternalDropdownState) {
            setDropdownOpen(false);
        } else {
            setOpen(false);
        }
    });

    const dropdownLoadingStateHeight = dropdownHeight > 100 ? 100 : dropdownHeight - 8;

    const isDropdownOpened =
        (useExternalDropdownState ? dropdownOpen : open) && state !== 'disabled';

    return (
        <div
            className="flex flex-col gap-1 font-inter w-full min-w-36 transition-all"
            style={{ marginBottom: isDropdownOpened ? `${increaseComponentHeightBy}px` : 0 }}
            ref={dropdownRef}
        >
            <label className="text-small font-bold leading-1.25">
                {label && (
                    <div className="mb-1">
                        {label}
                        {isRequired && <sup className="text-red-500 leading-1 ml-0.5">*</sup>}
                    </div>
                )}

                <div className="relative">
                    <button
                        onClick={toggleOpen}
                        disabled={state === 'disabled'}
                        className={`${selectClassName}  flex justify-between items-center`}
                    >
                        {selectedOption ? (
                            showAsBadge ? (
                                <div className="w-[calc(100%-25px)] flex justify-start">
                                    <Badge
                                        text={selectedOption.name}
                                        color={selectedOption.badgeColor || badgeColor || 'blue'}
                                    />
                                </div>
                            ) : (
                                <div className="flex items-center justify-between gap-2 w-[calc(100%-25px)]">
                                    <div className="truncate flex items-center gap-2">
                                        <p className="truncate">{selectedOption.name}</p>
                                        {selectedOption.trailingBadge && (
                                            <Badge
                                                text={selectedOption.trailingBadge.text}
                                                color={selectedOption.trailingBadge.color}
                                            />
                                        )}
                                    </div>
                                    {selectedOption.trailingTagText && (
                                        <Tag text={selectedOption.trailingTagText} />
                                    )}
                                </div>
                            )
                        ) : (
                            <span className="text-neutral-300 truncate">{placeholder}</span>
                        )}
                        {includeRemoveAction && selectedOption ? (
                            <span className="" onClick={handleRemoveSelectedValue}>
                                <SvgIcon icon={CloseCircleLineIcon} size="medium" color="neutral" />
                            </span>
                        ) : (
                            <span className="">
                                <SvgIcon icon={ArrowDownSLineIcon} size="medium" color="neutral" />
                            </span>
                        )}
                    </button>

                    {isDropdownOpened && (
                        <div
                            className="absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm flex flex-col px-2 py-2 lg:px-2.5"
                            style={{ maxHeight: `${dropdownHeight}px` }}
                        >
                            {optionsLoading && (
                                <div
                                    className="w-full flex items-center justify-center"
                                    style={{
                                        minHeight: `${dropdownLoadingStateHeight}px`,
                                        maxHeight: `${dropdownLoadingStateHeight}px`,
                                    }}
                                >
                                    <Loading withText={false} />
                                </div>
                            )}
                            {!optionsLoading && (
                                <>
                                    {showClientSideFilterBar && (
                                        <div className="px-2 py-1 mb-1">
                                            <ClientSideFilteredSearchBar
                                                list={sortedOptions || []}
                                                setFilteredList={setFilteredOptions}
                                                size="xs"
                                                placeholder={filterSearchBarPlaceholder}
                                            />
                                        </div>
                                    )}
                                    {filteredOptions.map((option) => (
                                        <div
                                            key={option.id}
                                            onClick={(e) => {
                                                if (areOptionsDisabled) {
                                                    e?.stopPropagation();
                                                    e?.preventDefault();
                                                    return;
                                                }
                                                onChange(option.id, name);
                                            }}
                                            className="relative cursor-pointer select-none py-0.5"
                                            {...(logAs ? { 'data-dd-action-name': logAs } : {})}
                                        >
                                            <SelectOption
                                                option={option}
                                                isSelected={option.id === value}
                                                showAsABadge={showOptionsAsBadge}
                                            />
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    )}
                </div>
            </label>

            {state === 'error' && errorMessage && (
                <div className="flex items-center gap-1">
                    <SvgIcon icon={ErrorWarningLineIcon} color="#E95B69" size="medium" />
                    <p className="text-extraSmall text-red-500 leading-1.25">{errorMessage}</p>
                </div>
            )}

            {tipText && (state !== 'error' || !errorMessage) && (
                <div className="flex items-center gap-1">
                    <SvgIcon icon={ErrorWarningLineIcon} color="#5E6470" size="medium" />
                    <p className="text-extraSmall text-neutral-300 leading-1.25">{tipText}</p>
                </div>
            )}
        </div>
    );
}
