/**
 * @name GenericIcon
 * This class is a wrapper around the icons in the Icons folder. It allows us to use Icons by simply passing in a string.
 *
 * eg: <GenericIcon iconName="whatever" height={24} width={24} />
 *
 * This is useful for nested components that need to use icons.
 *
 * Eg:
 *
 * const Page = ({ headerIcon: string, footerIcon: string }) => {
 *  return (
 *     <>
 *      <Header icon={headerIcon} />
 *      <Footer icon={footerIcon} />
 *     </>
 *   )
 * };
 *
 *  Both header and footer can simply take strings and pass them to GenericIcon as opposed to passing around
 *  elements or mapping the name to the icons within the respective components.
 *
 *
 * #How to add to this file
 * Implement a new icon in src/design-system/Icons/ folder and ensure it mimics the implementation of the other icons.
 *
 * eg:
 * const StarIcon = (props) => (
 * <svg width={props.width} height={props.height} viewBox="0 0 24 24">
 *   <polygon fill={props.color} points={points} />
 * </svg>
 *
 *  Then import and add the icon to the iconNameMap object below.
 *
 * Potential improvements
 * 1. We could generate this file based on folder contents
 * 2. We could pull this into a local npm package
 * 3. We could change the naming to match the file name
 * iconNameMap = {
 *  AddCircleLineIcon,  // No downcasing the first letter
 *    as opposed to
 *  addCircleLineIcon: AddCircleLineIcon,
 * }
 * 4. We could import the name with a different case
 *      eg: import addCircleLineIcon from "../Icons/AddCircleLineIcon";
 *      as opposed to import AddCircleLineIcon from "../Icons/AddCircleLineIcon";
 */

import React from 'react';
import PropTypes from 'prop-types';

import AddCircleLineIcon from 'design-system/Icons/AddCircleLineIcon';
import AddLineIcon from 'design-system/Icons/AddLineIcon';
import AiGenerateIcon from 'design-system/Icons/AiGenerateIcon';
import ArrowDownSLineIcon from 'design-system/Icons/ArrowDownSLineIcon';
import ArrowGoBackLineIcon from 'design-system/Icons/ArrowGoBackLineIcon';
import ArrowUpDownLineIcon from 'design-system/Icons/ArrowUpDownLineIcon';
import ArrowUpSLineIcon from 'design-system/Icons/ArrowUpSLineIcon';
import CloseCircleLineIcon from 'design-system/Icons/CloseCircleLineIcon';
import CloseIcon from './CloseIcon';
import CloseLineIcon from 'design-system/Icons/CloseLineIcon';
import DeleteBack2LineIcon from 'design-system/Icons/DeleteBack2LineIcon';
import DeleteBin4LineIcon from 'design-system/Icons/DeleteBin4LineIcon';
import DraggableIcon from 'design-system/Icons/DraggableIcon';
import EqualizerFillIcon from 'design-system/Icons/EqualizerFillIcon';
import ErrorWarninglineIcon from './ErrorWarningLineIcon';
import ExpandRightLineIcon from 'design-system/Icons/ExpandRightLineIcon';
import FileCopyLineIcon from 'design-system/Icons/FileCopyLineIcon';
import FlashlightFillIcon from 'design-system/Icons/FlashlightFillIcon';
import FlowChartIcon from 'design-system/Icons/FlowChartIcon';
import FullscreenExitLineIcon from 'design-system/Icons/FullscreenExitLineIcon';
import FullscreenLineIcon from 'design-system/Icons/FullscreenLineIcon';
import HelperIcon from 'design-system/Icons/HelperIcon';
import IndeterminateCircleLineIcon from 'design-system/Icons/IndeterminateCircleLineIcon';
import InformationLineIcon from 'design-system/Icons/InformationLineIcon';
import LightbulbFlashFillIcon from 'design-system/Icons/LightbulbFlashFillIcon';
import LightbulbFlashLineIcon from 'design-system/Icons/LightbulbFlashLineIcon';
import LoaderFillIcon from 'design-system/Icons/LoaderFillIcon';
import LogoutBoxRLineIcon from 'design-system/Icons/LogoutBoxRLineIcon';
import LoopRightFillIcon from 'design-system/Icons/LoopRightFillIcon';
import More2FillIcon from 'design-system/Icons/More2FillIcon';
import OpenAiFillIcon from 'design-system/Icons/OpenAIFillIcon';
import PlaceholderIcon from './PlaceholderIcon';
import Robot2FillIcon from 'design-system/Icons/Robot2FillIcon';
import Robot2LineIcon from 'design-system/Icons/Robot2LineIcon';
import Search2LineIcon from 'design-system/Icons/Search2LineIcon';
import SendPlane2FillIcon from 'design-system/Icons/SendPlane2FillIcon';
import Settings3LineIcon from 'design-system/Icons/Settings3LineIcon';
import Settings6LineIcon from 'design-system/Icons/Settings6LineIcon';
import SettingsLineIcon from 'design-system/Icons/SettingsLineIcon';
import ShareBoxLineIcon from './ShareBoxLineIcon';
import SpaceShipLineIcon from 'design-system/Icons/SpaceShipLineIcon';
import Sparkling2FillIcon from 'design-system/Icons/Sparkling2FillIcon';
import StarIcon from 'design-system/Icons/StarIcon';
import SubtractLineIcon from 'design-system/Icons/SubtractLineIcon';
import TerminalBoxLineIcon from 'design-system/Icons/TerminalBoxLineIcon';
import EditIcon from 'design-system/Icons/EditIcon';
import AddToLibraryIcon from 'design-system/Icons/AddToLibraryIcon';
import EditLineIcon from './EditLineIcon';
import SharedWithIcon from './SharedWithIcon';
import CheckboxCircleLineIcon from './CheckboxCircleLineIcon';
import HistoryLineIcon from './HistoryLineIcon';
import CopyIcon from './CopyIcon';
import EyeOffIcon from './EyeOffIcon';
import EyeIcon from './EyeIcon';
import SkipBackIcon from './SkipBackIcon';
import SquareIcon from './SquareIcon';
import CheckSquareIcon from './CheckSquareIcon';
import FileList3LineIcon from './FileList3LineIcon';
import PlusCircleIcon from './PlusCircleIcon';
import DatabaseIcon from './DatabaseIcon';
import RefreshIcon from './RefreshIcon';
import RefreshCcwIcon from './RefreshCcwIcon';
import InboxIndicatorIcon from './InboxIndicatorIcon';
import UserSharedLineIcon from './UserSharedLineIcon';
import Share2 from './Share2';
import PublishedIcon from './PublishedIcon';
import ArrowUpIcon from './ArrowUpIcon';
import InboxArchiveLineIcon from './InboxArchiveLineIcon';
import PlayCircleIcon from './PlayCircleIcon';
import ArchiveLineIcon from './ArchiveLineIcon';
import CheckboxLineIcon from './CheckboxLineIcon';

export const iconNameMap = {
    close: CloseIcon,
    equalizerFill: EqualizerFillIcon,
    errorWarningLine: ErrorWarninglineIcon,
    placeholder: PlaceholderIcon,
    shareBoxLine: ShareBoxLineIcon,
    star: StarIcon,
    addCircleLineIcon: AddCircleLineIcon,
    addLineIcon: AddLineIcon,
    addToLibrary: AddToLibraryIcon,
    aiGenerateIcon: AiGenerateIcon,
    archiveLineIcon: ArchiveLineIcon,
    arroDownSLineIcon: ArrowDownSLineIcon,
    arrowGoBackLineIcon: ArrowGoBackLineIcon,
    arrowUpDownLineIcon: ArrowUpDownLineIcon,
    arrowUpIcon: ArrowUpIcon,
    arrowUpSLineIcon: ArrowUpSLineIcon,
    checkboxCircleLineIcon: CheckboxCircleLineIcon,
    checkboxLineIcon: CheckboxLineIcon,
    checkSquareIcon: CheckSquareIcon,
    closeCircleLineIcon: CloseCircleLineIcon,
    closeIcon: CloseIcon,
    closeLineIcon: CloseLineIcon,
    copyIcon: CopyIcon,
    databaseIcon: DatabaseIcon,
    deleteBack2LineIcon: DeleteBack2LineIcon,
    deleteBin4LineIcon: DeleteBin4LineIcon,
    draggableIcon: DraggableIcon,
    editIcon: EditIcon,
    editLineIcon: EditLineIcon,
    equalizerFillIcon: EqualizerFillIcon,
    eyeIcon: EyeIcon,
    eyeOffIcon: EyeOffIcon,
    errorWarninglineIcon: ErrorWarninglineIcon,
    expandRightLineIcon: ExpandRightLineIcon,
    fileCopyLineIcon: FileCopyLineIcon,
    fileList3LineIcon: FileList3LineIcon,
    flashlightFillIcon: FlashlightFillIcon,
    flowChartIcon: FlowChartIcon,
    fullscreenExitLineIcon: FullscreenExitLineIcon,
    fullscreenLineIcon: FullscreenLineIcon,
    helperIcon: HelperIcon,
    historyIcon: HistoryLineIcon,
    inboxArchiveLineIcon: InboxArchiveLineIcon,
    inboxIndicatorIcon: InboxIndicatorIcon,
    indeterminateCircleLineIcon: IndeterminateCircleLineIcon,
    informationLineIcon: InformationLineIcon,
    lightbulbFlashFillIcon: LightbulbFlashFillIcon,
    lightbulbFlashLineIcon: LightbulbFlashLineIcon,
    loaderFillIcon: LoaderFillIcon,
    logoutBoxRLineIcon: LogoutBoxRLineIcon,
    loopRightFillIcon: LoopRightFillIcon,
    more2FillIcon: More2FillIcon,
    openAiFillIcon: OpenAiFillIcon,
    placeholderIcon: PlaceholderIcon,
    playCircleIcon: PlayCircleIcon,
    plusCircleIcon: PlusCircleIcon,
    publishedIcon: PublishedIcon,
    refreshCcwIcon: RefreshCcwIcon,
    refreshIcon: RefreshIcon,
    robot2FillIcon: Robot2FillIcon,
    robot2LineIcon: Robot2LineIcon,
    search2LineIcon: Search2LineIcon,
    sendPlane2FillIcon: SendPlane2FillIcon,
    settings3LineIcon: Settings3LineIcon,
    settings6LineIcon: Settings6LineIcon,
    settingsLineIcon: SettingsLineIcon,
    share2: Share2,
    shareBoxLineIcon: ShareBoxLineIcon,
    sharedWithIcon: SharedWithIcon,
    skipBackIcon: SkipBackIcon,
    spaceShipLineIcon: SpaceShipLineIcon,
    sparkling2FillIcon: Sparkling2FillIcon,
    squareIcon: SquareIcon,
    starIcon: StarIcon,
    subtractLineIcon: SubtractLineIcon,
    terminalBoxLineIcon: TerminalBoxLineIcon,
    userSharedLineIcon: UserSharedLineIcon,
};

export const iconNames = Object.keys(iconNameMap);

export default function GenericIcon({ iconName, height, width }) {
    const Icon = iconNameMap[iconName];

    return <Icon height={height} width={width} />;
}

GenericIcon.propTypes = {
    iconName: PropTypes.oneOf(iconNames).isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
};
