import React, { createContext, useState } from 'react';

export const SwrRequestKeysToMutateContext = createContext();

export const SwrRequestKeysToMutateProvider = ({ children }) => {
    const [requestKeysToMutate, setRequestKeysToMutate] = useState({
        templatesLibrary: [],
        jobs: [],
        projects: [],
        threads: [],
        executions: [],
        docs: [],
        bases: [],
        promptsInPlaybooks: [],
        processes: [],
        scenarios: [],
        bots: [],
    });

    return (
        <SwrRequestKeysToMutateContext.Provider
            value={{
                requestKeysToMutate,
                setRequestKeysToMutate,
            }}
        >
            {children}
        </SwrRequestKeysToMutateContext.Provider>
    );
};
