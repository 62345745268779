import { useEffect, useRef, useState } from 'react';
import { mutate } from 'swr';

import { mergeNewDataIntoCurrent } from '../helpers/mergeNewDataIntoCurrent';
import { addUniqueElementToArray } from '../helpers/addUniqueElementToArray';
import useCustomPagination, { DEFAULT_LIMIT } from './useCustomPagination';

export const useFetchOptionsForPaginatedSelectWithSWR = ({
    client,
    route,
    searchParams = {},
    formatResponseToOptions,
    limit = DEFAULT_LIMIT,
}) => {
    const [options, setOptions] = useState([]);
    const [totalOptions, setTotalOptions] = useState(0);

    const [page, setPage] = useState(1);
    const [canLoadMoreOptions, setCanLoadMoreOptions] = useState(false);

    const isFirstRender = useRef(true);
    const [requestSearchParams, setRequestSearchParams] = useState(searchParams); // is used to avoid request with wrong offset when prop searchParams is changing
    const stringifiedSearchParams = JSON.stringify(searchParams);

    const [isFirstRequestCompleted, setIsFirstRequestCompleted] = useState(false); // indicates if the first data is already received
    const requestKeysToMutate = useRef([]);

    const {
        data: results,
        loading,
        hasNextPage,
        total,
        key,
    } = useCustomPagination({
        pageIndex: page - 1,
        searchParams: requestSearchParams,
        client,
        route,
        limit,
    });

    useEffect(() => {
        setOptions((prevResults) => {
            if (!prevResults && !results) {
                return [];
            } else {
                const newData = formatResponseToOptions(results);
                return mergeNewDataIntoCurrent(prevResults || [], newData || []);
            }
        });

        if (page === 1 && results) {
            setIsFirstRequestCompleted(true);
        }

        if (hasNextPage !== undefined) {
            setCanLoadMoreOptions(hasNextPage);
        }
    }, [results]);

    useEffect(() => {
        if (total && !totalOptions) {
            setTotalOptions(total);
        }
    }, [total]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        // if searchParams has changed reset current data and make request with new search params
        resetCurrentData();
        setRequestSearchParams(searchParams || {});
    }, [stringifiedSearchParams]);

    useEffect(() => {
        if (key) {
            requestKeysToMutate.current = addUniqueElementToArray(
                key,
                requestKeysToMutate.current || []
            );
        }
    }, [key]);

    const resetCurrentData = () => {
        setPage(1);
        setOptions([]);
        setTotalOptions(0);
        setIsFirstRequestCompleted(false);
    };

    const revalidateAllRequestKeys = () => {
        requestKeysToMutate.current?.forEach((key) => {
            mutate(key, null, true);
        });
    };

    return {
        options,
        setOptions,
        optionsLoading: loading,
        canLoadMoreOptions,
        setPage,
        totalOptions,
        isFirstRequestCompleted,
        requestKeysToMutate: requestKeysToMutate.current || [],
        revalidateAllRequestKeys,
    };
};
