import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import client from '../../services/general-api';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import FormFieldWrapper from '../FormFieldWrapper/FormFieldWrapper';
import MultiSelect from '../MultiSelect/MultiSelect';

const MultiDatabaseInput = ({
    name,
    addedOptions,
    details,
    state,
    label,
    isRequired = false,
    errorMessage = '',
    tipText = '',
    handleChange,
    placeholder = 'Select an option and click + to add them',
}) => {
    const { endpoint, filters, fields } = details;
    const { label: labelFieldName, value: valueFieldName } = fields || {};

    const formattedSearchParams = useRef(() =>
        Object.entries(filters).reduce((acc, [key, value]) => {
            const formattedValue = Array.isArray(value) ? value.join(',') : value;
            return { ...acc, [key]: formattedValue };
        }, {})
    );

    const { options, optionsLoading, totalOptions, setPage, canLoadMoreOptions } =
        useFetchOptionsForPaginatedSelectWithSWR({
            client,
            route: endpoint,
            searchParams: formattedSearchParams.current || {},
            formatResponseToOptions,
        });

    function formatResponseToOptions(results) {
        return results?.map((item) => ({
            id: item.id,
            name: item[labelFieldName],
            value: item[valueFieldName],
        }));
    }

    const handleAddNewOption = (_, { id, name, value }) => {
        handleChange((item) => ({
            ...item,
            value: [...(item.value || []), { id, label: name, value }],
        }));
    };

    const handleRemoveOption = (id) => {
        if (state === 'disabled') return;

        handleChange((item) => ({
            ...item,
            value: item.value?.filter((option) => option.id !== id) || [],
        }));
    };

    const addedOptionsIds = addedOptions.map(({ id }) => id);

    const initiallyAddedOptionsInfo = useRef(() =>
        addedOptions.map(({ id, label, value }) => ({ id, name: label, value }))
    );

    return (
        <FormFieldWrapper label={label} isRequired={isRequired} state={state}>
            <MultiSelect
                name={name}
                addedOptions={addedOptionsIds}
                optionsForSelect={options}
                optionsLoading={optionsLoading}
                state={state}
                handleAddNewOption={handleAddNewOption}
                handleRemoveOption={handleRemoveOption}
                includeClientSideFiltering
                usePaginatedSelect
                canLoadMore={canLoadMoreOptions}
                totalOptionsCount={totalOptions}
                fetchOptions={() => setPage((page) => page + 1)}
                placeholder={placeholder}
                initiallyAddedOptionsInfoIfPaginatedSelect={initiallyAddedOptionsInfo.current || []}
                tipText={tipText}
                errorMessage={errorMessage}
            />
        </FormFieldWrapper>
    );
};

MultiDatabaseInput.propTypes = {
    name: PropTypes.string,
    addedOptions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            value: PropTypes.any,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    details: PropTypes.shape({
        endpoint: PropTypes.string.isRequired,
        filters: PropTypes.object,
        fields: PropTypes.shape({
            key: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    state: PropTypes.oneOf(['default', 'error', 'disabled']),
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    errorMessage: PropTypes.string,
    tipText: PropTypes.string,
    placeholder: PropTypes.string,
};

export default MultiDatabaseInput;
