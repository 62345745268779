export const PROVIDERS = {
    openai: 'openai',
    anthropic: 'anthropic',
    perplexity: 'perplexity',
    // weaviate: 'weaviate',
    hubspot: 'hubspot',
    serper: 'serper',
    fireflies: 'fireflies',
    notion: 'notion',
    airtable: 'airtable',
    ayrshare: 'ayrshare',
    asana: 'asana',
    apify: 'apify',
    slack: 'slack',
    google: 'google',
    github: 'github',
    rapidapi: 'rapidapi',
    firecrawl: 'firecrawl',
    smartscout: 'smartscout',
};

export const PROVIDERS_LABEL = {
    openai: 'OpenAI',
    anthropic: 'Anthropic',
    // weaviate: 'Weaviate',
    perplexity: 'Perplexity',
    hubspot: 'HubSpot',
    serper: 'Serper API',
    fireflies: 'Fireflies',
    notion: 'Notion',
    airtable: 'Airtable',
    ayrshare: 'Ayrshare',
    asana: 'Asana',
    apify: 'Apify',
    slack: 'Slack',
    google: 'Google',
    github: 'GitHub',
    rapidapi: 'Rapid API',
    firecrawl: 'Firecrawl',
    smartscout: 'SmartScout',
};

export const PROVIDERS_LOGO = {
    openai: '/images/providers-logo/openai-logo.png',
    anthropic: '/images/providers-logo/anthropic-logo.png',
    perplexity: '/images/providers-logo/perplexity-logo.png',
    // weaviate: '/images/providers-logo/weaviate-logo.png',
    hubspot: '/images/providers-logo/hubspot-logo.png',
    serper: '/images/providers-logo/serper-logo.png',
    fireflies: '/images/providers-logo/fireflies-logo.png',
    notion: '/images/providers-logo/notion-logo.png',
    airtable: '/images/providers-logo/airtable-logo.png',
    ayrshare: '/images/providers-logo/ayrshare-logo.png',
    asana: '/images/providers-logo/asana-logo.png',
    apify: '/images/providers-logo/apify-logo.png',
    slack: '/images/providers-logo/slack-logo.png',
    google: '/images/providers-logo/google-logo.png',
    github: '/images/providers-logo/github-logo.png',
    rapidapi: '/images/providers-logo/rapidapi-logo.png',
    firecrawl: '/images/providers-logo/firecrawl-logo.png',
    smartscout: '/images/providers-logo/smartscout-logo.png',
};

export const providersCardData = {
    [PROVIDERS.openai]: {
        title: PROVIDERS_LABEL.openai,
        description: 'Connect your OpenAI API key to run your prompts using their models.',
        logoPath: PROVIDERS_LOGO.openai,
    },
    [PROVIDERS.anthropic]: {
        title: PROVIDERS_LABEL.anthropic,
        description: 'Connect your Anthropic API key to run your prompts using their models.',
        logoPath: PROVIDERS_LOGO.anthropic,
    },
    [PROVIDERS.perplexity]: {
        title: PROVIDERS_LABEL.perplexity,
        description: 'Connect your Perplexity API key to run your prompts using their models.',
        logoPath: PROVIDERS_LOGO.perplexity,
    },
    // [PROVIDERS.weaviate]: {
    //     title: PROVIDERS_LABEL.weaviate,
    //     description:
    //         'Connect your Weaviate cluster to start embedding your business knowledge into a vector store.',
    //     logoPath: PROVIDERS_LOGO.weaviate,
    // },
    [PROVIDERS.hubspot]: {
        title: PROVIDERS_LABEL.hubspot,
        description: 'Connect your HubSpot account to reimagine your sales and marketing efforts.',
        logoPath: PROVIDERS_LOGO.hubspot,
    },
    [PROVIDERS.serper]: {
        title: PROVIDERS_LABEL.serper,
        description:
            'Connect your Serper API account to supercharge your processes with real-time web search capabilities.',
        logoPath: PROVIDERS_LOGO.serper,
    },
    [PROVIDERS.fireflies]: {
        title: PROVIDERS_LABEL.fireflies,
        description: 'Connect your Fireflies account to enhance your meeting productivity.',
        logoPath: PROVIDERS_LOGO.fireflies,
    },
    [PROVIDERS.notion]: {
        title: PROVIDERS_LABEL.notion,
        description:
            'Connect your Notion account to amplify your collaboration and organization capabilities.',
        logoPath: PROVIDERS_LOGO.notion,
    },
    [PROVIDERS.airtable]: {
        title: PROVIDERS_LABEL.airtable,
        description:
            'Connect your Airtable account to connect your business processes to your data.',
        logoPath: PROVIDERS_LOGO.airtable,
    },
    [PROVIDERS.ayrshare]: {
        title: PROVIDERS_LABEL.ayrshare,
        description:
            'Connect your Ayrshare account to connect your social media accounts for content processes.',
        logoPath: PROVIDERS_LOGO.ayrshare,
    },
    [PROVIDERS.asana]: {
        title: PROVIDERS_LABEL.asana,
        description:
            'Connect your Asana account to bring your processes into the personal productivity space.',
        logoPath: PROVIDERS_LOGO.asana,
    },
    [PROVIDERS.rapidapi]: {
        title: PROVIDERS_LABEL.rapidapi,
        description:
            'Connect your Rapid API account to supercharge your processes with new APIs.',
        logoPath: PROVIDERS_LOGO.rapidapi,
    },
    // [PROVIDERS.slack]: {
    //     title: PROVIDERS_LABEL.slack,
    //     description:
    //         'Connect your Slack account to streamline your team’s collaboration and communication.',
    //     logoPath: PROVIDERS_LOGO.slack,
    // },
    [PROVIDERS.apify]: {
        title: PROVIDERS_LABEL.apify,
        description:
            'Connect your Apify account to unlock web scraping and automation capabilities.',
        logoPath: PROVIDERS_LOGO.apify,
    },
    [PROVIDERS.google]: {
        title: PROVIDERS_LABEL.google,
        description:
            'Connect your Google Gemini API key to run your prompts using their models.',
        logoPath: PROVIDERS_LOGO.google,
    },
    [PROVIDERS.github]: {
        title: PROVIDERS_LABEL.github,
        description:
            'Connect your GitHub account to work with your repositories and automate your workflows.',
        logoPath: PROVIDERS_LOGO.github,
    },
    [PROVIDERS.firecrawl]: {
        title: PROVIDERS_LABEL.firecrawl,
        description:
            'Connect your Firecrawl account to unlock smarter web scraping and crawling.',
        logoPath: PROVIDERS_LOGO.firecrawl,
    },
    [PROVIDERS.smartscout]: {
        title: PROVIDERS_LABEL.smartscout,
        description:
            'Connect your SmartScout account to leverage Amazon data.',
        logoPath: PROVIDERS_LOGO.smartscout,
    },
};

export const providersForNonAgencyOrg = [PROVIDERS.openai, PROVIDERS.anthropic];

export const providersForAgencyOrg = [
    PROVIDERS.openai,
    PROVIDERS.anthropic,
    PROVIDERS.perplexity,
    // PROVIDERS.weaviate,
    PROVIDERS.hubspot,
    PROVIDERS.serper,
    PROVIDERS.fireflies,
    PROVIDERS.notion,
    PROVIDERS.airtable,
    PROVIDERS.ayrshare,
    PROVIDERS.asana,
    PROVIDERS.apify,
    // PROVIDERS.slack,
    PROVIDERS.google,
    PROVIDERS.rapidapi,
    PROVIDERS.firecrawl,
    PROVIDERS.smartscout,
];

export const comingSoonProviders = [];
