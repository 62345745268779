import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { API } from 'constants';
import templateClient from '../../services/template-api';
import libraryClient from '../../services/library-api';
import { defaultErrorMessage } from '../../constants/errorMessages';

import useUser from '../../hooks/useUser';

import { wsFailReconnectErrorMessage } from '../../services/websocket';
import { ListeningMultipleLoadingsContext } from '../../contexts/websoketListeningMultipleLoadingsContext';

import {
    checkIfEveryStepFormDataValid,
    formatStepFormDataForRequestBody,
    getSetStepFormDataFunction,
    getSettingsFormDataInitialState,
    handleStepsFormErrors,
} from '../../helpers/settingsFormUtils';
import { checkIfFormDataChanged } from '../../helpers/checkIfFormDataChanged';

import ErrorBanner from '../../design-system/ErrorBanner/ErrorBanner';
import SettingStepCollapsibleContainer from './SettingStepCollapsibleContainer/SettingStepCollapsibleContainer';
import SettingStepForm from './SettingStepForm/SettingStepForm';
import NavigationGuard from '../NavigationGuard/NavigationGuard';
import Alert from '../../design-system/Alert/Alert';
import errorWarningLineIcon from '../../design-system/Icons/ErrorWarningLineIcon';
import CheckLineIcon from '../../design-system/Icons/CheckLineIcon';
import { Button } from '../../design-system';

ProcessOverviewSettingsTabSection.propTypes = {
    processDetails: PropTypes.object.isRequired,
    setProcessDetails: PropTypes.func.isRequired,
    shouldShowHiddenSettings: PropTypes.bool,
    variant: PropTypes.oneOf(['primary', 'secondary']),
    isFormDisabled: PropTypes.bool,
};

// to use this component, wrap the page that use it with Provider: <WsMultipleLoadingsProvider messageType="update_process">
function ProcessOverviewSettingsTabSection({
    processDetails,
    setProcessDetails,
    shouldShowHiddenSettings = true,
    variant = 'primary',
    isFormDisabled = false,
}) {
    const { id: processId, default_version } = processDetails;
    const { settings, process_template_version: processTemplateVersionId } = default_version;
    const { user } = useUser();

    const tabContainerRef = useRef(null);

    // keeps form data in format {[step.order]: [stepFormData]}
    const [formData, setFormData] = useState(
        settings.reduce(
            (acc, step) => ({
                ...acc,
                [step.order]: getSettingsFormDataInitialState(step.settings),
            }),
            {}
        )
    );

    // originalFormData contains the form initial state and is used for tracking changes
    const originalFormData = useRef(formData);
    const [areChangesToSave, setAreChangesToSave] = useState(false);
    const shouldTrackChanges = variant === 'secondary';

    // keeps data in format {[step.order]: {Boolean (isCurrentStepRequiredFieldsFilled)}}
    // no need to set the initial state because in every SettingStepForm on Component mount useEffect is called and set the correct value
    // this state is used specifically to avoid checking all form fields when a field of a particular step changes.
    // validation is performed locally at the level of each step only if a field of that step is changed.
    const [areStepsRequiredFieldsFilled, setAreStepsRequiredFieldsFilled] = useState({});

    const allRequiredFieldsAreFilled = useMemo(
        () => Object.values(areStepsRequiredFieldsFilled).every(Boolean),
        [areStepsRequiredFieldsFilled]
    );

    const [isExpanded, setIsExpanded] = useState({});

    const [stepsErrorData, setStepsErrorData] = useState({}); // keeps data in format {[step.order]: {isError: true, errorMessage: "error Message"}}
    const [errorBannerMessage, setErrorBannerMessage] = useState(null); // used for general (not step-specific) errors
    const [errorAlert, setErrorAlert] = useState(null);
    const [successAlert, setSuccessAlert] = useState(null);

    const isProcessCustom = !processTemplateVersionId;

    const {
        loadingHandlers,
        setLoadingHandlers,
        addRequestUuid,
        setShouldConnectWebsocket,
        tryReconnectIfWebsocketClosed,
    } = useContext(ListeningMultipleLoadingsContext);

    useEffect(() => {
        if (!isProcessCustom) {
            setShouldConnectWebsocket(true);
        }
    }, [isProcessCustom]);

    useEffect(() => {
        if (shouldTrackChanges) {
            const isFormChanged = checkIfFormDataChanged(originalFormData.current, formData);

            if (isFormChanged !== areChangesToSave) {
                setAreChangesToSave(isFormChanged);
            }
        }
    }, [formData]);

    const getRequestBody = () => {
        const settings = Object.values(formData).reduce((acc, stepFormData, index) => {
            if (index === 0) {
                return acc;
            }
            return [...acc, ...formatStepFormDataForRequestBody(stepFormData)];
        }, []);

        return {
            name: formData['0'][0]?.value, // value from the "Process Name" step's input (first step)
            organization: user.organization.id,
            process: processId,
            settings,
        };
    };

    const expandAllErrorsSteps = (stepsErrorData) => {
        const expandedSteps = {};

        Object.entries(stepsErrorData).map(([order, stepErrorData]) => {
            if (stepErrorData?.isError) {
                expandedSteps[order] = true;
            }
        });

        setIsExpanded(expandedSteps);
    };

    const refreshOriginalFormData = () => {
        originalFormData.current = formData;
        setFormData((prevData) => ({ ...prevData })); // to trigger useEffect that checks if form data changed
    };

    const handleUpdateCustomProcessSettings = async () => {
        const name = formData['0'][0]?.value;
        await libraryClient.patch(`${API.ROUTES.library.process}${processId}/`, { name });
        setProcessDetails((prevData) => ({ ...prevData, name }));
        setSuccessAlert({ message: 'Process settings successfully updated!' });
        setLoadingHandlers((prevState) => ({ ...prevState, [processId]: null }));
        refreshOriginalFormData();
    };

    const handleUpdateDeployedFromTemplateProcessSettings = async () => {
        await tryReconnectIfWebsocketClosed();

        const requestBody = getRequestBody();
        const { data } = await templateClient.patch(
            `${API.ROUTES.template.processTemplateVersion}${processTemplateVersionId}/deploy/`,
            requestBody
        );
        if (data.status === 'started' && data.request_uuid) {
            addRequestUuid(data.request_uuid);
        }
    };

    const handleStatusChange = (message) => {
        if (message.status === 'success') {
            const successMessage = {
                primary: 'Process settings successfully updated!',
                secondary: `${message.data?.name} Agent settings successfully updated!`,
            };
            setSuccessAlert({
                message: successMessage[variant],
            });
            setProcessDetails((prevData) => ({ ...prevData, name: message.data?.name }));
            refreshOriginalFormData();
        }

        if (message.status === 'error') {
            setErrorBannerMessage(message.error);
            setErrorAlert({ message: defaultErrorMessage });
        }
    };

    const updateSettings = async () => {
        // remove all step errors before submitting
        setStepsErrorData({});
        setErrorBannerMessage(null);

        try {
            // check if all required fields are filled and if all inputted data is valid (json-input has a valid json value)
            const { isValid, updatedStepsErrorData } = checkIfEveryStepFormDataValid(
                formData,
                setFormData,
                !shouldShowHiddenSettings
            );

            if (!isValid) {
                setStepsErrorData(updatedStepsErrorData);
                expandAllErrorsSteps(updatedStepsErrorData);
                setErrorAlert({ message: 'All required fields should be filled and valid.' });
                return;
            }

            setLoadingHandlers((prevState) => ({ ...prevState, [processId]: handleStatusChange }));

            if (isProcessCustom) {
                await handleUpdateCustomProcessSettings();
            }
            if (!isProcessCustom) {
                await handleUpdateDeployedFromTemplateProcessSettings();
            }
        } catch (e) {
            setLoadingHandlers((prevState) => ({ ...prevState, [processId]: null }));

            if (e.message === wsFailReconnectErrorMessage) {
                setErrorAlert({
                    message: 'Oops! Something went wrong. Please try submitting again.',
                });
                return;
            }

            handleStepsFormErrors({
                e,
                setErrorBannerMessage,
                setStepsErrorData,
                setErrorAlert,
                formData,
                setFormData,
                settings,
                expandAllErrorsSteps,
            });
        }
    };

    const sortArrayByOrder = (array) => array?.sort((a, b) => a.order - b.order) || [];

    // if step has just hidden settings, hide this step as well
    const sortedFilteredSettings = useMemo(() => {
        if (!shouldShowHiddenSettings) {
            const filteredSettings = settings.filter((step) => {
                const stepHasNonHiddenSettings = step.settings.find((item) => !item.is_hidden);
                return stepHasNonHiddenSettings;
            });

            return sortArrayByOrder(filteredSettings);
        }

        return sortArrayByOrder(settings);
    }, [settings]);

    const containerClassName = classNames('w-full flex flex-col gap-5', {
        'p-5 sm:p-6 bg-white rounded-2xl': variant === 'primary',
    });

    const isLoading = !!loadingHandlers[processId];
    const updateButtonState = isFormDisabled
        ? 'disabled'
        : isLoading
        ? 'loading'
        : allRequiredFieldsAreFilled && (areChangesToSave || !shouldTrackChanges)
        ? 'default'
        : 'disabled';

    return (
        <>
            {errorBannerMessage && <ErrorBanner errorMessage={errorBannerMessage} />}

            <div className={containerClassName} ref={tabContainerRef}>
                {variant === 'primary' && (
                    <p className="font-heading-bold text-heading-bold-s text-black">
                        Your Settings
                    </p>
                )}
                <div className="flex flex-col gap-5">
                    {sortedFilteredSettings.map((stepDetails) => (
                        <SettingStepCollapsibleContainer
                            key={stepDetails.order}
                            stepDetails={stepDetails}
                            isExpanded={isExpanded[stepDetails.order]}
                            setIsExpanded={setIsExpanded}
                            variant={variant}
                        >
                            <SettingStepForm
                                stepFormData={formData[stepDetails.order]}
                                setStepFormData={getSetStepFormDataFunction(
                                    stepDetails.order,
                                    setFormData
                                )}
                                stepOrder={stepDetails.order}
                                setAreStepsRequiredFieldsFilled={setAreStepsRequiredFieldsFilled}
                                currentStepErrorData={stepsErrorData[stepDetails.order]}
                                instructions={stepDetails.instructions}
                                tabContainerRef={tabContainerRef}
                                shouldShowHiddenSettings={shouldShowHiddenSettings}
                                variant={variant}
                                isFormDisabled={isFormDisabled}
                            />
                        </SettingStepCollapsibleContainer>
                    ))}
                </div>
                {variant === 'primary' && (
                    <div className="flex justify-end">
                        <Button
                            type="secondary"
                            size="xs"
                            text="Update Settings"
                            state={updateButtonState}
                            onClick={updateSettings}
                        />
                    </div>
                )}
                {variant === 'secondary' && (
                    <div className="flex justify-between items-center gap-1">
                        <Button
                            type="link"
                            size="md"
                            text="Go to Agent Detail Page"
                            onClick={() => window.open(`/agent/${processId}`, '_blank')}
                        />

                        <Button
                            type="secondary"
                            size="md"
                            text="Update Agent"
                            state={updateButtonState}
                            onClick={updateSettings}
                        />
                    </div>
                )}

                {variant === 'primary' && (
                    <NavigationGuard
                        when={isLoading}
                        promptMessage="Are you sure you want to leave? We’ll keep loading your process in the background."
                        customNavigationBlockerMessageSegment="We’ll keep loading your process in the background."
                    />
                )}
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={errorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
            {successAlert && (
                <Alert
                    status="positive"
                    message={successAlert.message}
                    icon={CheckLineIcon}
                    handleClose={() => setSuccessAlert(null)}
                />
            )}
        </>
    );
}

export default ProcessOverviewSettingsTabSection;
