import { API } from 'constants';
import operateClient from '../services/operate-api';

import {
    defaultErrorMessage,
    emptyFieldErrorMessage,
    invalidInputErrorMessage,
} from '../constants/errorMessages';
import { checkIfInputtedJsonIsValid, getMappedFormErrors } from './settingsFormUtils';
import { LINKED_DOC_REGEX } from '../components/NewJobForm/RichTextWithLinkedDocs/RichTextWithLinkedDocs';
import { normalizeMarkdown } from './normalizeMarkdown';

const actionsWithArrayValue = ['multi-input', 'multi-select', 'multi-dropdown', 'multi-db'];

// these functions are used for the Start New Job, Start New Project forms and Base Entry form

export const getStartNewJobFormDataInitialState = (inputs, allowLinkDocs = true) => {
    return (
        inputs?.map((input) => {
            if (input.action === 'json-input' && !input.value) {
                return { ...input, value: {}, state: 'default' }; // set initial value for "json-input"
            }

            if (actionsWithArrayValue.includes(input.action) && !input.value) {
                return { ...input, value: [], state: 'default' }; // set initial value for 'multi-input', 'multi-select', 'multi-dropdown'
            }

            if (input.action === 'toggle' && !input.value) {
                return { ...input, value: false, state: 'default' }; // set initial value for 'toggle'
            }

            if (input.action === 'richtext-input' && allowLinkDocs) {
                let docs_mapping = {};
                if (input.value) {
                    docs_mapping = extractKeys(input.value);
                }

                return { ...input, value: input.value || '', state: 'default', docs_mapping }; // add docs_mapping to 'richtext-input'
            }

            return { ...input, state: 'default' };
        }) || []
    );
};

export const mergeValuesIntoInputs = (inputs, values) => {
    return inputs.map((input) => {
        const value = values[input.key];

        return { ...input, value };
    });
};

export function extractKeys(value) {
    const keyPattern = /\[([^\]]+)\]/g;
    const keys = {};
    let match;

    while ((match = keyPattern.exec(value)) !== null) {
        keys[match[1]] = null;
    }

    return keys;
}

export function extractDocIds(inputs) {
    return inputs.reduce((acc, input) => {
        const keys = {};
        let match;

        while ((match = LINKED_DOC_REGEX.exec(input.value || '')) !== null) {
            keys[match[1]] = null;
        }

        return { ...acc, ...keys };
    }, {});
}

export const checkIsRequiredFieldFilled = (item) => {
    const isFieldNotFilled =
        (actionsWithArrayValue.includes(item.action) && !item.value?.length) ||
        (!actionsWithArrayValue.includes(item.action) &&
            !item.value &&
            item.value !== 0 &&
            item.value !== false);

    return item.is_required ? !isFieldNotFilled : true;
};

export const checkIsValidDocsMapping = (item) => {
    let isError = false;

    if (item.action === 'richtext-input' && item.value && item.docs_mapping) {
        const isUnfilledDocumentSelector = Object.values(item.docs_mapping).some((id) => !id);
        const isMissedVariableKeysInValue = Object.keys(item.docs_mapping).some(
            (key) => !item.value.includes(`[${key}]`)
        );

        isError = isUnfilledDocumentSelector || isMissedVariableKeysInValue;
    }

    return !isError;
};

const getDefaultEmptyFormFieldErrorMessage = (action) => {
    switch (action) {
        case 'multi-input':
            return 'Please add at least one input.';
        case 'multi-dropdown':
            return 'Please select at least one option.';
        case 'single-select':
            return 'Please select an option.';
        case 'multi-select':
            return 'Please select an option.';
        case 'multi-db':
            return 'Please select at least one option.';
        case 'single-dropdown':
            return 'Please select an option.';
        default:
            return emptyFieldErrorMessage;
    }
};

export const checkIfAllRequiredInputsAreFilled = (formData) => {
    return formData.every(
        (item) => checkIsRequiredFieldFilled(item) && checkIsValidDocsMapping(item)
    );
};

export const checkIsFormDataValid = (formData, setFormData) => {
    const { areErrorFields, updatedFormData } = formData.reduce(
        (acc, item) => {
            // check if inputted Json is valid
            const invalidJsonError = checkIfInputtedJsonIsValid(acc, item);
            if (invalidJsonError) {
                return invalidJsonError;
            }

            // check if all required fields are filled
            const isRequiredFieldFilled = checkIsRequiredFieldFilled(item);
            if (!isRequiredFieldFilled) {
                return {
                    areErrorFields: true,
                    updatedFormData: [
                        ...acc.updatedFormData,
                        {
                            ...item,
                            state: 'error',
                            errorMessage: getDefaultEmptyFormFieldErrorMessage(item.action),
                        },
                    ],
                };
            }

            const shouldCheckDocsMapping = item.action === 'richtext-input' && item.docs_mapping;
            if (shouldCheckDocsMapping) {
                const isDocsMappingValid = checkIsValidDocsMapping(item);
                if (!isDocsMappingValid) {
                    return {
                        areErrorFields: true,
                        updatedFormData: [
                            ...acc.updatedFormData,
                            {
                                ...item,
                                state: 'error',
                                errorMessage:
                                    'Please complete all document selectors and ensure they are referenced in the text field.',
                            },
                        ],
                    };
                }
            }

            return { ...acc, updatedFormData: [...acc.updatedFormData, item] };
        },
        { areErrorFields: false, updatedFormData: [] }
    );

    if (areErrorFields) {
        setFormData(updatedFormData);
        return false; // means that there is a form data error
    }

    return true; // means that there are no form data errors
};

export const replaceDocsVariableMappings = (value, docs_mapping) => {
    const variableKeys = Object.keys(docs_mapping)
        .map((key) => `\\[${key}\\]`)
        .join('|');
    const regex = new RegExp(variableKeys, 'g');

    return value.replace(regex, (match) => {
        const key = match.slice(1, -1);
        return `\${document.${docs_mapping[key]}}`;
    });
};

export const getFormattedFormValue = (input, docs_mapping) => {
    let formattedValue = input.value;

    // make value type "number" for action === 'single-input' && type === 'number' (note: we do not have to do this for item.type === 'integer' as that value is already parsedInt)
    if (input.action === 'single-input' && input.type === 'number' && input.value) {
        formattedValue = parseFloat(input.value);
    }

    // parse Json value
    if (input.action === 'json-input' && input.value && typeof input.value === 'string') {
        formattedValue = JSON.parse(input.value);
    }

    // replace docs variables by corresponding ids
    if (input.action === 'richtext-input' && docs_mapping) {
        const docsVariableKeys = Object.keys(docs_mapping);
        const areMappedDocs = !!docsVariableKeys.length;

        if (areMappedDocs) {
            const replacedValue = replaceDocsVariableMappings(input.value, docs_mapping);
            formattedValue = normalizeMarkdown(replacedValue);
        } else {
            formattedValue = normalizeMarkdown(input.value);
        }
    }

    if (input.action === 'richtext-input' && !docs_mapping) {
        formattedValue = normalizeMarkdown(input.value);
    }

    return formattedValue;
};

export const formatInputsToFormData = (formData) =>
    formData.map(({ state, errorMessage, docs_mapping, ...input }) => ({
        ...input,
        value: getFormattedFormValue(input, docs_mapping),
    }));

export const formatInputsToDictionaryFormDataView = (formData) =>
    formData.reduce(
        (acc, { state, errorMessage, docs_mapping, ...input }) => ({
            ...acc,
            [input.key]: getFormattedFormValue(input, docs_mapping),
        }),
        {}
    );

export const replaceDocsVariableMappingInFormData = (formData) =>
    formData.map((input) => {
        if (input.action === 'richtext-input' && input.docs_mapping && input.value) {
            const docsVariableKeys = Object.keys(input.docs_mapping);
            const areMappedDocs = !!docsVariableKeys.length;

            if (areMappedDocs) {
                const replacedValue = replaceDocsVariableMappings(input.value, input.docs_mapping);
                const value = normalizeMarkdown(replacedValue);
                return { ...input, value };
            } else {
                return { ...input, value: normalizeMarkdown(input.value) };
            }
        } else if (input.action === 'richtext-input') {
            return { ...input, value: normalizeMarkdown(input.value || '') };
        }
        return input;
    });

export const handleRequestErrors = ({ error, setErrorAlert, formData, setFormData }) => {
    const inputsErrors = error.response?.data?.inputs;

    if (inputsErrors) {
        // handle inputs error
        const updatedFormData = getMappedFormErrors(inputsErrors, formData, 'key');
        setFormData(updatedFormData);
    } else {
        setErrorAlert({ message: defaultErrorMessage, statusCode: error.response?.statusCode });
    }
};

export const getMappedClientStartNewJobFormErrors = (inputsErrorArr, formData) => {
    const errorKeysMap = new Map(inputsErrorArr.map((item) => [item.key, item]));

    const updatedFormData = formData.map((item) => {
        // if there is an error message for current field set it
        const currentFieldErrorObject = errorKeysMap.get(item.key);
        if (currentFieldErrorObject) {
            return {
                ...item,
                state: 'error',
                errorMessage: currentFieldErrorObject?.error || invalidInputErrorMessage,
            };
        } else {
            return item;
        }
    });

    return updatedFormData;
};

export const handleClientStartNewJobRequestErrors = ({
    error,
    setErrorAlert,
    formData,
    setFormData,
}) => {
    const inputsErrors = error.response?.data?.error;

    if (Array.isArray(inputsErrors)) {
        // handle inputs error
        const updatedFormData = getMappedClientStartNewJobFormErrors(inputsErrors, formData);
        setFormData(updatedFormData);
    }
    setErrorAlert({ message: defaultErrorMessage, statusCode: error.response?.statusCode });
};

export const startNewJob = async ({
    formData,
    setFormData,
    processId,
    setNewJobId = () => {},
    setStartNewJobState,
    setErrorAlert,
}) => {
    const isFormDataValid = checkIsFormDataValid(formData, setFormData);
    if (!isFormDataValid) {
        return;
    }

    try {
        setStartNewJobState('loading');
        setNewJobId(null);

        const inputs = formatInputsToFormData(formData);
        const requestBody = { process: processId, auto: true, inputs };

        const { data } = await operateClient.post(API.ROUTES.operate.thread, requestBody);
        setNewJobId(data.id);

        setStartNewJobState('success');
    } catch (error) {
        setStartNewJobState('default');
        handleClientStartNewJobRequestErrors({ error, setErrorAlert, formData, setFormData });
    }
};
